import {
  createStore,
  action,
  Action,
  thunk,
  Thunk,
  computed,
  Computed,
} from "easy-peasy";
import { DEFAULT_CAMPAIGN_STATE, DEFAULT_USER_STATE } from "./constants";
import { createHeaders, bearerTokenHeader } from "./headers";

const DEFAULT_FIELDS = {
  name: " ",
  shortDescription: "",
  ownerName: "",
};

export interface Message {
  id: number;
  data: string;
  sentAt: Date;
  senderName: string;
  campaign: string;
  deleted?: boolean;
}

export interface Campaign {
  id: string;
  name: string;
  shortDescription: string;
  ownerName: string;
  ownerEmail: string;
  startDate: string;
  recipientName: string;
  recipientPhoneNumber: string;
  messages: Message[] | null;
  createdAt: string;
  started: boolean;
  startedAt?: string;
  endedAt?: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  sessionId: string;
  campaigns: Campaign[];
}

interface Loading {
  isLoading: boolean;
  message: string;
}

export interface StoreModel {
  authToken: string;
  updateAuthToken: Action<StoreModel, string>;
  campaign: Campaign;
  hasMessages: Computed<StoreModel, boolean>;
  numberOfActiveMessages: Computed<StoreModel, number>;
  user: User;
  loading: Loading;
  updateLoading: Action<StoreModel, Loading>;
  updateCampaign: Action<StoreModel, Campaign>;
  pushCampaign: Thunk<StoreModel, any>;
  pushMessage: Thunk<
    StoreModel,
    { id: number; data?: string; deleted?: boolean }
  >;
  updateUser: Action<StoreModel, User>;
  fetchCampaign: Thunk<StoreModel, string>;
  fetchUser: Thunk<StoreModel>;
  registerCampaign: Thunk<
    StoreModel,
    {
      recipientName: string;
      recipientPhoneNumber: string;
      ownerEmail?: string;
      ownerName?: string;
    }
  >;
  registerUser: Thunk<
    StoreModel,
    {
      email: string;
      name: string;
    }
  >;
  addMessage: Thunk<
    StoreModel,
    {
      id: string;
      data: string;
      from: string;
      senderEmail: string;
      senderPhoneNumber: string;
    }
  >;
  wrapWithLoading: Thunk<
    StoreModel,
    {
      payload?: any;
      action: any;
      message?: string;
    }
  >;
}

export const store = createStore<StoreModel>({
  authToken: localStorage.getItem("auth-token") || "",
  updateAuthToken: action((state, payload) => {
    localStorage.setItem("auth-token", payload);
    state.authToken = payload;
  }),
  campaign: DEFAULT_CAMPAIGN_STATE,
  loading: {
    isLoading: false,
    message: "",
  },
  updateLoading: action((state, payload) => {
    state.loading = payload;
  }),
  hasMessages: computed((state) => {
    return (
      state.campaign.messages?.some((message) => !message.deleted) || false
    );
  }),
  numberOfActiveMessages: computed((state) => {
    return (
      state.campaign.messages?.filter((message) => !message.deleted)?.length ||
      0
    );
  }),
  user: DEFAULT_USER_STATE,
  updateCampaign: action((state, payload) => {
    state.campaign = payload;
  }),
  updateUser: action((state, payload) => {
    state.user = payload;
  }),
  fetchUser: thunk(async (actions, payload, { getState }) => {
    const headersToPass = getState().authToken
      ? [bearerTokenHeader(getState().authToken)]
      : [];

    const res = await fetch(
      `https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-users`,
      {
        method: "GET",
        headers: createHeaders(headersToPass),
      }
    );

    const json = await res.json();

    if (res.status !== 200) {
      throw new Error(json.error.message);
    }

    if (res.status === 200) {
      actions.updateUser(json.user);
    }
  }),
  fetchCampaign: thunk(async (actions, payload: string, { getState }) => {
    const headersToPass = getState().authToken
      ? [bearerTokenHeader(getState().authToken)]
      : [];

    const res = await fetch(
      `https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-campaigns/${payload}`,
      {
        method: "GET",
        headers: createHeaders(headersToPass),
      }
    );

    const json = await res.json();

    if (res.status !== 200) {
      throw new Error(json.error.message);
    }

    if (res.status === 200) {
      actions.updateCampaign(json.campaign);
    }
  }),
  registerCampaign: thunk(
    async (
      actions,
      payload: {
        recipientName: string;
        recipientPhoneNumber: string;
        ownerEmail?: string;
        ownerName?: string;
      },
      { getState }
    ) => {
      const headersToPass = getState().authToken
        ? [bearerTokenHeader(getState().authToken)]
        : [];

      const { name, email } = getState().user;

      const normalizedRecipientPhoneNumber = payload.recipientPhoneNumber.replace(
        /[^\d]+/g,
        ""
      );

      const res = await fetch(
        "https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-campaigns",
        {
          method: "POST",
          headers: createHeaders(headersToPass),
          body: JSON.stringify({
            campaign: {
              ...DEFAULT_FIELDS,
              ...(!payload.ownerEmail && !payload.ownerName
                ? { ownerEmail: email, ownerName: name }
                : {}),
              ...payload,
              recipientPhoneNumber: normalizedRecipientPhoneNumber,
            },
          }),
        }
      );

      const json = await res.json();

      if (res.status !== 200) {
        throw new Error(json.message);
      }

      if (res.status === 200) {
        actions.updateCampaign(json.campaign);
        actions.updateUser(json.user);
        actions.updateAuthToken(json.user.sessionToken);
      }

      return json.campaign.id;
    }
  ),
  registerUser: thunk(
    async (
      actions,
      payload: {
        email: string;
        name: string;
      },
      { getState }
    ) => {
      const res = await fetch(
        "https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-users",
        {
          method: "POST",
          body: JSON.stringify({
            user: payload,
          }),
        }
      );

      const json = await res.json();

      if (res.status !== 200) {
        throw new Error(json.message);
      }

      return "Success";
    }
  ),
  pushCampaign: thunk(async (actions, payload, { getState }) => {
    const { campaign } = getState();
    const newCampaignState = {
      ...campaign,
      ...payload,
    };
    const res = await fetch(
      "https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-campaigns",
      {
        method: "PUT",
        headers: createHeaders([bearerTokenHeader(getState().authToken)]),
        body: JSON.stringify({
          campaign: newCampaignState,
        }),
      }
    );
    const json = await res.json();
    if (res.status !== 200) {
      throw new Error(json.message);
    }
    if (res.status === 200) {
      actions.updateCampaign(newCampaignState);
    }
  }),
  pushMessage: thunk(async (actions, { id, data, deleted }, { getState }) => {
    const { campaign } = getState();
    const { messages } = campaign;

    if (!messages) {
      return;
    }

    const currentMessage = messages.find((m: Message) => m.id === id);

    if (data && currentMessage?.data) {
      currentMessage.data = data;
    }

    if (deleted && currentMessage) {
      currentMessage.deleted = deleted;
    }

    const res = await fetch(
      "https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-campaign-messages",
      {
        method: "PUT",
        headers: createHeaders([bearerTokenHeader(getState().authToken)]),
        body: JSON.stringify({
          message: { ...currentMessage },
        }),
      }
    );
    const json = await res.json();
    if (res.status !== 200) {
      throw new Error(json.message);
    }
    if (res.status === 200) {
      await actions.updateCampaign(campaign);
    }
    return;
  }),
  addMessage: thunk(async (actions, payload, { getState }) => {
    const { id, data, from, senderEmail } = payload;
    const res = await fetch(
      "https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-campaigns",
      {
        method: "PATCH",
        headers: createHeaders(),
        body: JSON.stringify({
          id,
          message: {
            data,
            senderName: from,
            senderEmail,
          },
        }),
      }
    );

    const json = await res.json();

    if (res.status !== 200) {
      throw new Error(json.message);
    }

    if (res.status === 200) {
      // TODO(Marcb) handle success
    }
  }),
  wrapWithLoading: thunk(async (actions, payload, { getState }) => {
    const { payload: actionPayload, action, message } = payload;
    actions.updateLoading({
      isLoading: true,
      message: message || "",
    });
    let result;
    let error;
    try {
      result = await action(actionPayload);
    } catch (e) {
      error = e;
    }

    actions.updateLoading({
      isLoading: false,
      message: "",
    });

    if (error) {
      throw error;
    }

    return result;
  }),
});
