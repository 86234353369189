import React from "react";

const QuestionAndAnswer = ({ q, a }) => {
  return (
    <div>
      <dt className="text-lg leading-6 font-medium text-gray-900">{q}</dt>
      <dd className="mt-2 text-base text-gray-500">{a}</dd>
    </div>
  );
};

export const Faq = ({ qaList }) => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Frequently asked questions
            </h2>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              {qaList.map(({ q, a }) => (
                <QuestionAndAnswer q={q} a={a} />
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
