import React from "react";
import { Transition } from "@headlessui/react";

type Props = {
  isOpen: boolean;
  primaryAction: any;
  secondaryAction?: any;
  primaryText: JSX.Element | string;
  secondaryText?: JSX.Element | string;
  content: JSX.Element | string;
  headerIcon?: JSX.Element | null;
  headerText?: JSX.Element | string;
};

export const Modal = ({
  isOpen,
  primaryAction,
  secondaryAction,
  primaryText,
  secondaryText,
  content,
  headerIcon,
  headerText,
}: Props) => (
  <Transition show={isOpen}>
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={secondaryAction}
            className="absolute inset-0 bg-gray-500 opacity-75"
          />
        </Transition.Child>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          ​
        </span>
        <Transition.Child
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5
          pb-4 text-left overflow-hidden shadow-xl transform transition-all
          sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div>
            {headerIcon && (
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                {headerIcon}
              </div>
            )}
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {headerText}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{content}</p>
              </div>
            </div>
          </div>
          <div
            className={`${
              secondaryText
                ? "sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                : ""
            } mt-5 sm:mt-6`}
          >
            <button
              onClick={primaryAction}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            >
              {primaryText}
            </button>
            {secondaryText && (
              <button
                onClick={secondaryAction}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                {secondaryText}
              </button>
            )}
          </div>
        </Transition.Child>
      </div>
    </div>
  </Transition>
);
