type Header = [string, string];

const CONTENT_TYPE_APPLICATION_JSON: Header = [
  "Content-Type",
  "application/json",
];
export const bearerTokenHeader = (token: string): Header => [
  "Authorization",
  `Bearer ${token}`,
];

const DEFAULT_HEADERS = [CONTENT_TYPE_APPLICATION_JSON];

export const createHeaders = (headerArr: Header[] = []): Headers => {
  const headerArrWithDefaults = [...DEFAULT_HEADERS, ...headerArr];

  let allHeaders: any = {};

  headerArrWithDefaults.forEach((header) => {
    const [key, value] = header;
    allHeaders[key] = value;
  });
  return new Headers(allHeaders);
};
