import React, { useEffect } from "react";
import { Dashboard } from "../../../components/application/dashboard";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { Campaign } from "../../../store/store";
import {
  RANDOM_COLORS,
  createHashBucket,
  convertToTwoCharacters,
} from "../../../helpers/application";
import { Link } from "react-router-dom";

const CampaignList = ({ campaigns }: { campaigns: Campaign[] }) => {
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {campaigns.map(({ startedAt, endedAt, id, recipientName }) => {
        let status = "Not Started";
        if (startedAt) {
          status = "In Progress";
        }
        if (endedAt) {
          status = "Finished";
        }
        return (
          <Link to={`dashboard/c/${id}`}>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">
                      {recipientName}
                    </h3>
                  </div>
                  <p className="mt-1 text-gray-400 text-sm truncate">
                    {status}
                  </p>
                </div>
                <span
                  className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${
                    RANDOM_COLORS[
                      createHashBucket(recipientName, RANDOM_COLORS.length)
                    ]
                  }`}
                >
                  <span className="font-medium leading-none text-white">
                    {convertToTwoCharacters(recipientName)}
                  </span>
                </span>
              </div>
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export const Campaigns = () => {
  const {
    user: { campaigns },
  } = useStoreState((s) => s);
  const { fetchUser } = useStoreActions((a) => a);

  useEffect(() => {
    fetchUser().catch((e: Error) => {
      // if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
      //   setCampaignNotFound(true);
      // }
    });
  }, [fetchUser]);

  return (
    <Dashboard title="Campaigns">
      {campaigns ? (
        <CampaignList campaigns={campaigns} />
      ) : (
        <span>You don't have any campaigns yet</span>
      )}
    </Dashboard>
  );
};
