export const RANDOM_COLORS = [
  "bg-blue-500",
  "bg-blue-600",
  "bg-blue-700",
  "bg-blue-800",
  "bg-green-500",
  "bg-green-600",
  "bg-green-700",
  "bg-green-800",
  "bg-indigo-500",
  "bg-indigo-600",
  "bg-indigo-700",
  "bg-indigo-800",
  "bg-pink-500",
  "bg-pink-600",
  "bg-pink-700",
  "bg-pink-800",
  "bg-gray-500",
  "bg-gray-600",
  "bg-gray-700",
  "bg-gray-800",
  "bg-red-500",
  "bg-red-600",
  "bg-red-700",
  "bg-red-800",
];

export const hashCode = function (s: string) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const createHashBucket = (str: string, numBuckets: number): number => {
  return Math.abs(hashCode(str) % numBuckets);
};

export const convertToTwoCharacters = (str: string): string => {
  const strSplit = str.split(" ").filter(Boolean);
  if (strSplit.length === 1) {
    return str.slice(0, 1).toUpperCase();
  } else {
    return `${strSplit[0][0]}${strSplit[1][0]}`.toUpperCase();
  }
};
