import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dashboard } from "../../../components/application/dashboard";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { ERRORS } from "./messages";

const StartCampaign = () => {
  const { pushCampaign } = useStoreActions((a) => a);
  const {
    campaign: { started, recipientName },
  } = useStoreState((s) => s);
  const handleStartCampaignClick = () => {
    if (started) {
      return;
    }
    // @ts-ignore
    pushCampaign({ started: true })
      .then(() => {})
      .catch((e: Error) => {
        // Todo (marcb) catch error
        console.log(e);
      });
  };

  return (
    <div className="shadow sm:rounded-lg bg-gray-50">
      <div className="px-4 py-5 sm:p-6">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="start-sending-label"
        >
          {started ? (
            <span>Your campaign has started! </span>
          ) : (
            <span>Start Sending Compliments To {recipientName} </span>
          )}
        </h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p id="start-sending-description">
              {started ? (
                <>
                  <div>
                    We're sending daily kind reminders to {recipientName}.
                    <br />
                    <br />
                    <strong>Note:</strong> You cannot stop this campaign.
                  </div>
                </>
              ) : (
                <>
                  <div>
                    Clicking will start sending a daily message {recipientName}
                    . It will also immediately send a welcome note.
                    <br />
                    <br />
                    <strong>Note:</strong> You can still add additional messages
                    to the campaign even after starting.
                  </div>
                </>
              )}
            </p>
          </div>
          <div
            className={` mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center`}
          >
            <button
              type="button"
              className={`${
                started
                  ? "bg-indigo-600 opacity-20 cursor-not-allowed"
                  : "bg-gray-400"
              } bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              aria-pressed="false"
              aria-labelledby="start-sending-label"
              onClick={handleStartCampaignClick}
            >
              <span className="sr-only">Use setting</span>

              <span
                aria-hidden="true"
                className={`${
                  started ? "translate-x-5" : "translate-x-0"
                } translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CampaignSettings = () => {
  const { wrapWithLoading, fetchCampaign } = useStoreActions((a) => a);

  const [campaignNotFound, setCampaignNotFound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    campaign: { ownerEmail },
  } = useStoreState((s) => s);
  const { id: campaignId } = useParams<{ id: string }>();

  useEffect(() => {
    wrapWithLoading({ action: fetchCampaign, payload: campaignId })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      });
  }, [fetchCampaign, campaignId, wrapWithLoading]);

  let content;

  if (campaignNotFound) {
    content = <div>Campaign {campaignId} not found</div>;
  } else if (!isLoaded) {
    content = <div>loading...</div>;
  } else if (!ownerEmail) {
    content = <div>You need to log in</div>;
  } else {
    content = (
      <>
        <StartCampaign />
      </>
    );
  }

  return (
    <Dashboard title="Campaign Settings">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">{content}</div>
      </div>
    </Dashboard>
  );
};
