import React, { useEffect } from "react";
import { Dashboard } from "../../../components/application/dashboard";
import { useStoreActions } from "../../../store/hooks";
export const UserSettings = () => {
  const { fetchUser, wrapWithLoading } = useStoreActions((a) => a);

  useEffect(() => {
    wrapWithLoading({ action: fetchUser }).catch((e: Error) => {
      // if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
      //   setCampaignNotFound(true);
      // }
    });
  }, [fetchUser, wrapWithLoading]);

  return (
    <Dashboard title="Settings">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">Settings coming soon</div>
      </div>
    </Dashboard>
  );
};
