export const Step2 = () => {
  return (
    <section className="bg-white dark:bg-gray-800">
      <div className="container mx-auto px-6 py-8">
        <div className="flex items-center justify-center flex-col-reverse lg:flex-row">
          <div className="mt-8 lg:mt-0">
            <div className="flex items-center justify-center lg:justify-start">
              <div className="max-w-lg">
                <img
                  className="w-3/4 h-128 object-cover object-center rounded-md shadow m-auto"
                  src="step2.png"
                  alt="Step 2"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col">
            <h2 className="text-gray-800 dark:text-gray-100 text-xl lg:text-3xl font-bold">
              Step 2
            </h2>
            <p className="text-gray-500 dark:text-gray-400 lg:max-w-md mt-4">
              Schedule kind and honest words about that person
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
