import React, { useState, useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from "../../store/hooks";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DEV_TEST_DATA_FLAG, COMPLIMENT_STARTERS } from "../../store/constants";
import { Alert } from "../../components/shared/alert";
import { NavbarT } from "../../components/marketing/navbar";
import { useDebounce } from "use-debounce";

export const ERRORS = {
  CAMPAIGN_FOUND_FOUND: "get campaign: datastore: no such entity",
};

const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const lowercaseFirstCharacter = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export function NewMessage() {
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    setError,
    getValues,
    setValue,
    watch,
  } = useForm();
  const [campaignNotFound, setCampaignNotFound] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { fetchCampaign, addMessage, wrapWithLoading } = useStoreActions(
    (a) => a
  );
  const [showSuccessMsg, updateShowSuccessMsg] = useState(false);
  const [preview, setPreview] = useState("");

  const data = watch("data");
  const from = watch("from");
  const [debouncedData] = useDebounce(data, 500);
  const [debouncedFrom] = useDebounce(from, 500);

  const {
    campaign: { id, recipientName },
  } = useStoreState((s) => s);
  const { id: campaignId } = useParams<{ id: string }>();

  const alertCallback = useCallback(() => {
    setValue("data", "");
    setPreview("");
  }, [setValue, setPreview]);

  useEffect(() => {
    if (showSuccessMsg) {
      setTimeout(() => {
        updateShowSuccessMsg(false);
      }, 4000);
    }
  }, [showSuccessMsg, updateShowSuccessMsg]);

  useEffect(() => {
    if (!id) {
      wrapWithLoading({
        action: fetchCampaign,
        payload: campaignId,
        message: "Loading Campaign",
      }).catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      });
    }
  }, [fetchCampaign, id, campaignId, wrapWithLoading]);

  useEffect(() => {
    const previewMessage = async () => {
      let { data, from } = getValues();

      if (!data) {
        setPreview("");
        return;
      }

      setImageLoading(true);
      if (data) {
        from = from ? from : "";
        await fetch(
          `https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-images?sender=${encodeURI(
            from
          )}&message=${encodeURI(data)}`
        )
          .then((response) => response.blob())
          .then((images) => {
            // Then create a local URL for that image and print it
            setPreview(URL.createObjectURL(images));
            setImageLoading(false);
          });
      }
    };
    previewMessage();
  }, [debouncedData, debouncedFrom, getValues, trigger]);

  if (campaignNotFound) {
    return (
      <>
        <NavbarT />
        <h2>Campaign {campaignId} not found</h2>
      </>
    );
  }

  if (!id) {
    return (
      <>
        <NavbarT />
        <div></div>
      </>
    );
  }

  const onSubmit = (props: any) => {
    if (!props.data) {
      return;
    }
    addMessage({ id, ...props })
      .then(() => {
        updateShowSuccessMsg(true);
      })
      .catch((e: Error) => {
        setError("submit", {
          type: "manual",
          message: e.message,
        });
      });
  };

  const handleComplimentStarterClick = () => {
    let randomCompliment =
      COMPLIMENT_STARTERS[
        Math.floor(Math.random() * COMPLIMENT_STARTERS.length)
      ];

    if (!randomCompliment.substring(0, 2).match(/^[A-Za-z ]+[^A-Za-z0-9]/)) {
      randomCompliment = lowercaseFirstCharacter(randomCompliment);
    }

    const nameCapitalized = capitalize(recipientName).split(" ")[0];
    const randomComplimentWithName = `${nameCapitalized}, ${randomCompliment}`;

    setValue("data", randomComplimentWithName);
  };

  return (
    <>
      <NavbarT />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full mt-4 lg:relative"
        action="#"
      >
        <div className="mx-auto max-w-7xl w-full lg:pt-8 pb-4 text-center">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl">
              <span className="block xl:inline">
                Please write one thing that is special about
              </span>
              <p className="block text-indigo-600">{recipientName}</p>
            </h1>
            <div>
              <textarea
                placeholder="Eg: You always know how to put a smile on my face"
                className="w-10/12 sm:w-96 mb-4 mt-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                ref={register({
                  required: DEV_TEST_DATA_FLAG,
                  validate: (value) => {
                    if (value.split("\n").length > 6) {
                      return "You have too many line breaks in your compliment!";
                    }

                    return true;
                  },
                })}
                name="data"
                autoComplete="off"
                maxLength={200}
                rows={5}
              />
              {errors.data && (
                <>
                  <br />
                  <span className="error-text">{errors.data.message} </span>
                </>
              )}
              <br />
              <span> Stuck? Try our:</span>
              <br />
              <div
                className="btn btn-orange mt-2 mb-2"
                onClick={handleComplimentStarterClick}
              >
                Compliment Starter
              </div>
              <br />
              to help you build a meaningful compliment!
              <br />
              <br />
              <hr className="w-96 m-auto" />
              <br />
              <label className="block text-sm font-medium text-gray-700">
                From:
              </label>
              <div className="">
                <input
                  type="text"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  ref={register({})}
                  name="from"
                  autoComplete="name"
                />
                {errors.from && (
                  <>
                    <br />
                    <span className="error-text">{errors.from.message} </span>
                  </>
                )}
              </div>
              <br />
              <label className="block text-sm font-medium text-gray-700">
                Your Email:{" "}
                <p
                  className="mt-1 text-xs text-gray-500"
                  id="email-description"
                >
                  (optional - For campaign updates)
                </p>
              </label>
              <div className="">
                <input
                  type="text"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  ref={register({})}
                  name="senderEmail"
                  autoComplete="email"
                />

                {errors.senderEmail && (
                  <>
                    <br />
                    <span className="error-text">
                      {errors.senderEmail.message}{" "}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
              lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
              fugiat aliqua.
            </p> */}
          </div>
        </div>
        <div className="relative w-full h-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          {imageLoading && (
            <div
              className="lg:visible invisible"
              id="loader-wrapper"
              style={{ position: "absolute" }}
            >
              <div id="loader" />
            </div>
          )}
          <div className="flex items-center justify-center lg:bg-gray-2-50  w-full h-full flex-col text-gray-200">
            {preview ? (
              <span className="px-8 text-xl tracking-tight font-bold text-gray-900 text-center">
                Preview of what we will send to {recipientName}:
              </span>
            ) : (
              <h1 className="invisible lg:visible px-8 text-xl tracking-tight font-bold text-gray-900 text-center">
                Image Preview
              </h1>
            )}

            {preview && (
              <div className="p-8 flex justify-center items-center">
                <img
                  className="shadow-2xl lg:w-8/12 sm:10/12"
                  src={preview}
                  alt=""
                />
              </div>
            )}
            <div className="flex text-center items-center justify-center flex-col absolute">
              <div>
                <Alert
                  isOpen={showSuccessMsg}
                  setIsOpen={updateShowSuccessMsg}
                  contentText={
                    <>
                      <span>Successfully Submitted!</span>
                      <br />
                      <span>
                        Feel free to add another Kind Reminder for{" "}
                        {recipientName}
                      </span>
                    </>
                  }
                  timeout={5000}
                  callback={alertCallback}
                />
                <div className="mb-2" />
              </div>
            </div>
            {preview && (
              <div className="mb-4 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <button
                    type="submit"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 md:py-4 md:text-lg md:px-10"
                  >
                    Submit
                  </button>
                  {errors.submit && (
                    <>
                      <br />
                      <span className="error-text">
                        {errors.submit.message}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
