export const stub = "";

export const DEV_TEST_DATA_FLAG = false;
export const DEV_CAMPAIGN_ID = "bDeT0BQ0MQPIBf0YDpVAxG1l";
export const MAX_DATE = 8640000000000000;

export const COMPLIMENT_STARTERS = [
  `You are my favourite person to spend time with`,
  `I get excited every time I see you.`,
  `I love making you laugh.`,
  `You’re my best friend.`,
  `I love every inch of you— even your toes.`,
  `You’ve got a sexy personality.`,
  `I feel lucky to be with you.`,
  `You always know how to make me feel at home.`,
  `I can fully be myself with you`,
  `I love your style.`,
  `You look beautiful even when you don’t try.`,
  `You make me want to be my best`,
  `The more I learn about you, the more I love you`,
  `I love talking to you.`,
  `You’re a great kisser.`,
  `You’re the best at cuddling.`,
  `The best part of my day is waking up next to you.`,
  `I have fun just being beside you doing nothing.`,
  `You have my heart.`,
  `You’re the better part of me.`,
  `You’re my family.`,
  `I’d climb a mountain for you.`,
  `I’m always looking forward to our next adventure.`,
  `You make me happy.`,
  `You have the sweetest heart.`,
  `I love looking at you.`,
  `You light up my life like nobody else.`,
  `Your cooking is amazing.`,
  `I never thought it could be this good.`,
  `I feel most alive by your side.`,
  `I smile thinking about you when I’m alone.`,
  `You’re everything to me.`,
  `You're a gift to those around you.`,
  `You're a smart cookie.`,
  `You are awesome!`,
  `You have impeccable manners.`,
  `You have the best laugh.`,
  `I appreciate you.`,
  `You are the most perfect you there is.`,
  `You are enough.`,
  `You're strong.`,
  `Your perspective is refreshing.`,
  `I'm grateful to know you.`,
  `You light up the room.`,
  `You should be proud of yourself.`,
  `You've got an awesome sense of humor!`,
  `You are really courageous.`,
  `Your kindness is a gift to all who encounter it.`,
  `You're even more beautiful on the inside than you are on the outside.`,
  `I'm inspired by you.`,
  `You're like a ray of sunshine on a really dreary day.`,
  `You are making a difference.`,
  `Thank you for being there for me.`,
  `You bring out the best in other people.`,
  `Your ability to recall random factoids at just the right time is impressive.`,
  `You're a great listener.`,
  `How is it that you always look great, even in sweatpants?`,
  `I bet you sweat glitter.`,
  `Hanging out with you is always a blast.`,
  `You always know -- and say -- exactly what I need to hear when I need to hear it.`,
  `You help me feel more joy in life.`,
  `Being around you makes everything better!`,
  `When you say, "I meant to do that," I totally believe you.`,
  `You're wonderful.`,
  `Jokes are funnier when you tell them.`,
  `When I'm down you always say something encouraging to help me feel better.`,
  `You are really kind to people around you.`,
  `You help me be the best version of myself.`,
  `Our community is better because you're in it.`,
  `You always find something special in the most ordinary things.`,
  `Everyone gets knocked down sometimes, but you always get back up and keep going.`,
  `You're a candle in the darkness.`,
  `You're a great example to others.`,
  `Being around you is like being on a happy little vacation.`,
  `You always know just what to say.`,
  `You could survive a Zombie apocalypse.`,
  `You're great at figuring stuff out.`,
  `Your voice is magnificent.`,
  `The people you love are lucky to have you in their lives.`,
  `You're like a breath of fresh air.`,
  `When you say you will do something, I trust you.`,
  `When you make up your mind about something, nothing stands in your way.`,
  `You seem to really know who you are.`,
  `Any team would be lucky to have you on it.`,
  `There's ordinary, and then there's you.`,
  `You're even better than a unicorn, because you're real.`,
  `The way you treasure your loved ones is incredible.`,
  `You're really something special.`,
  `Thank you for being you.`,
  `I’m glad you’re my friend.`,
  `I love spending time with you.`,
  `You are my lifeline.`,
  `You are my person.`,
  `You are a great parent to our kid(s).`,
  `I appreciate you.`,
  `You complete me.`,
  `You are my soul mate.`,
  `I’m so glad I married you.`,
  `You look beautiful.`,
  `You are the most amazing person in this world.`,
  `You make me feel loved.`,
  `I love how you patiently listen to me.`,
  `I respect you so much.`,
  `You’re an excellent partner.`,
  `I feel safe with you.`,
  `I can’t live a day without you.`,
  `You’re so thoughtful.`,
  `I am so happy that I found you.`,
  `You make my heart beat like a drum.`,
  `I am not afraid of anything because I have you.`,
  `Being your spouse is an honor.`,
  `Thank you for taking care of me.`,
  `I appreciate what you do for our family.`,
  `I want to grow old with you.`,
  `You’re my rock.`,
  `There’s no one like you.`,
  `You are a thoughtful person.`,
  `You’re amazing, and I can’t say that enough.`,
  `I love being by your side.`,
  `I thank you for everything you do around your house.`,
  `Our children are lucky to have you as a parent. You’re great.`,
  `You’ve helped me become a better person.`,
  `I’ll love you forever.`,
  `You are my guiding light.`,
  `You’re everything that I asked for.`,
  `I am amazed at how well you balance work and home life.`,
  `I love the way you appreciate the little things.`,
  `I can’t thank you enough for understanding me.`,
  `I am so glad that you chose to be in my life.`,
  `I am so proud of your achievements.`,
  `I owe you for my achievements and success.`,
  `I am not afraid of life’s ups and downs if I have you beside me.`,
  `I know I can face anything, because I have you.`,
  `You make me laugh.`,
  `You are the reason I can face my fears.`,
  `You are an indispensable part of the team.`,
  `You are really good at what you do.`,
  `I really enjoyed your contribution during the presentation.`,
  `I love your “Can Do” attitude.`,
  `I trust you.`,
  `You amaze me with your hard work.`,
  `It’s inspiring how well you perform, even under pressure.`,
  `Things have been crazy at work, but you took care of business with no complaints. Bravo!`,
  `Your work ethic speaks for itself.`,
  `You bring a lot of positivity to your work.`,
  `I appreciate your valuable advice.`,
  `Your work never ceases to amaze me.`,
  `I appreciate your insights and valuable advice.`,
  `You are so hardworking and caring, and I have always looked up to you.`,
  `Thank you for letting me explore the world.`,
  `If I am a confident, bold, and independent person today, it is all because of you.`,
  `Thank you for teaching me how to become a good person.`,
  `Your heart is full of love.`,
  `Our family is complete because of you.`,
  `Thank you for sacrificing so much for me.`,
  `I love you to the moon and back.`,
  `You’re the most positive person I know.`,
  `You’re my hero.`,
  `There’s no one like you.`,
  `You’re incredible.`,
  `I want to be just like you.`,
  `You have the biggest heart.`,
  `You’re my best friend.`,
  `I’ll always cherish your valuable advice.`,
  `You are so brave.`,
  `You love me at my worst.`,
  `You deserve credit for everything I have accomplished.`,
];

export const DEFAULT_CAMPAIGN_STATE = {
  id: "",
  name: "",
  shortDescription: "",
  ownerName: "",
  ownerEmail: "",
  startDate: "",
  recipientName: "",
  recipientPhoneNumber: "",
  messages: [],
  createdAt: "",
  started: false,
};

export const DEFAULT_USER_STATE = {
  id: "",
  name: "",
  email: "",
  sessionId: "",
  campaigns: [],
};
