import React from "react";
import { StoreProvider } from "easy-peasy";
import { store } from "../store/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Redirects } from "./redirects";

import { Home } from "../screens/marketing/home";
import { Signup } from "../screens/marketing/signup";
import { NewMessage } from "../screens/marketing/new-message";
import { Campaigns } from "../screens/application/user/campaigns";
import { UserSettings } from "../screens/application/user/settings";
import { NewCampaign } from "../screens/application/user/new-campaign";
import { AddMessage } from "../screens/application/campaigns/add-message";
import { Loading } from "../components/shared/loading/loading";
import { Messages } from "../screens/application/campaigns/messages";
import { CampaignSettings } from "../screens/application/campaigns/campaign-settings";
import { ShareCampaign } from "../screens/application/campaigns/share";
import { PrivateRoute } from "./authenicated-route";

export function Routes() {
  return (
    <StoreProvider store={store}>
      <Loading />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/new">
            <Signup />
          </Route>
          <Route exact path="/c/:id/">
            <NewMessage />
          </Route>
          <PrivateRoute exact path="/dashboard/" component={Campaigns} />
          <PrivateRoute
            exact
            path="/dashboard/settings/"
            component={UserSettings}
          />
          <PrivateRoute exact path="/dashboard/new/" component={NewCampaign} />
          <PrivateRoute exact path="/dashboard/c/:id/" component={Messages} />
          <PrivateRoute
            exact
            path="/dashboard/c/:id/add/"
            component={AddMessage}
          />
          <PrivateRoute
            exact
            path="/dashboard/c/:id/share/"
            component={ShareCampaign}
          />
          <PrivateRoute
            exact
            path="/dashboard/c/:id/settings/"
            component={CampaignSettings}
          />

          {Redirects}
        </Switch>
      </Router>
    </StoreProvider>
  );
}
