import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dashboard } from "../../../components/application/dashboard";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { ERRORS } from "./messages";

const CampaignLink = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const campaignLocation = `${window.location.origin}/c/${campaignId}`;

  const {
    campaign: { recipientName },
  } = useStoreState((s) => s);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="text-lg leading-6 font-medium text-gray-900">
          Send to friends to add kind messages for {recipientName}:
        </div>
        <div className="mt-4">
          <a
            className="link"
            href={campaignLocation}
            rel="noreferrer"
            target="_blank"
          >
            {" "}
            {campaignLocation}{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export const ShareCampaign = () => {
  const { wrapWithLoading, fetchCampaign } = useStoreActions((a) => a);

  const [campaignNotFound, setCampaignNotFound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    campaign: { ownerEmail },
  } = useStoreState((s) => s);
  const { id: campaignId } = useParams<{ id: string }>();

  useEffect(() => {
    wrapWithLoading({ action: fetchCampaign, payload: campaignId })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      });
  }, [fetchCampaign, campaignId, wrapWithLoading]);

  let content;

  if (campaignNotFound) {
    content = <div>Campaign {campaignId} not found</div>;
  } else if (!isLoaded) {
    content = <div>loading...</div>;
  } else if (!ownerEmail) {
    content = <div>You need to log in</div>;
  } else {
    content = (
      <>
        <CampaignLink />
      </>
    );
  }

  return (
    <Dashboard title="Share with Friends">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">{content}</div>
      </div>
    </Dashboard>
  );
};
