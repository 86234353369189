import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "../../store/hooks";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  RANDOM_COLORS,
  convertToTwoCharacters,
  createHashBucket,
} from "../../helpers/application";

import { Transition } from "@headlessui/react";
import {
  DEFAULT_CAMPAIGN_STATE,
  DEFAULT_USER_STATE,
} from "../../store/constants";
export const ERRORS = {
  CAMPAIGN_FOUND_FOUND: "get campaign: datastore: no such entity",
};

const SidebarLogo = () => (
  <Link to="/dashboard">
    <div className="flex justify-start items-center lg:flex-1 logo">
      <svg
        width="16px"
        height="16px"
        className="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
      <span>KindReminders</span>
    </div>
  </Link>
);

const SidebarLink = ({ svgPath, isMobile, to, name }: any) => {
  const { numberOfActiveMessages } = useStoreState((s) => s);

  let location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`${
        isActive
          ? "bg-gray-100 text-gray-900"
          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
      } group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100`}
    >
      <svg
        className={`${
          isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500"
        } ${isMobile ? "mr-4" : "mr-3"} h-6 w-6`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {svgPath}
      </svg>
      {/* hack: TODO(marcb) have better way to append variables dashboard links */}
      {`${name}${name === "Messages" ? ` (${numberOfActiveMessages})` : ""}`}
    </Link>
  );
};

const SIDEBAR_PRIMARY_LINKS = [
  {
    to: "/dashboard",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    ),
    name: "Campaigns",
  },
  {
    to: "/dashboard/new",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
      />
    ),
    name: "New Campaign",
  },
  {
    to: "/dashboard/settings",
    svgPath: (
      <>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </>
    ),
    name: "Settings",
  },
];

const SIDEBAR_SECONDARY_LINKS = [
  {
    to: "/dashboard/c/:id",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
      />
    ),
    name: "Messages",
  },
  {
    to: "/dashboard/c/:id/add",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
      />
    ),
    name: "Add Messages",
  },
  {
    to: "/dashboard/c/:id/share",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
      />
    ),
    name: "Share",
  },
  {
    to: "/dashboard/c/:id/settings",
    svgPath: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
      />
    ),
    name: "Campaign Settings",
  },
];

const OptionalCampaignLinks = ({ mobile }: any) => {
  const {
    campaign: { recipientName },
  } = useStoreState((s) => s);

  const { id: campaignId } = useParams<{ id: string }>();
  const sidebarLinks = SIDEBAR_SECONDARY_LINKS.map((props) => {
    return { ...props, to: props.to.split(":id").join(campaignId) };
  });

  let location = useLocation();
  const isOptionalCampaignLink = sidebarLinks.some(
    ({ to }) => location.pathname === to
  );

  if (!isOptionalCampaignLink || !recipientName) {
    return <></>;
  }

  return (
    <div className="pt-8">
      <h3
        className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
        id="teams-headline"
      >
        {recipientName}'s Campaign
      </h3>
      <div
        className="mt-1 space-y-1"
        role="group"
        aria-labelledby="teams-headline"
      >
        {sidebarLinks.map((props) => (
          <SidebarLink {...props} mobile />
        ))}
      </div>
    </div>
  );
};

const SidebarMobile = ({ isOpen, setIsOpen, name, logout }: any) => {
  return (
    <Transition show={isOpen}>
      <div className="md:hidden">
        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0"
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </Transition.Child>

          <Transition.Child
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="relative flex-1 flex flex-col max-w-xs w-full bg-white"
          >
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`${
                  isOpen ? "flex" : "hidden"
                } ml-1 items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
              >
                <span className="sr-only">Close sidebar</span>

                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <SidebarLogo />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                {SIDEBAR_PRIMARY_LINKS.map((props) => (
                  <SidebarLink {...props} mobile />
                ))}
                <OptionalCampaignLinks mobile />
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <div className="flex items-center">
                <div>
                  <span
                    className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${
                      RANDOM_COLORS[
                        createHashBucket(name, RANDOM_COLORS.length)
                      ]
                    }`}
                  >
                    <span className="font-medium leading-none text-white">
                      {convertToTwoCharacters(name)}
                    </span>
                  </span>
                </div>
                <div className="ml-3">
                  <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                    {name}
                  </p>
                  <p
                    onClick={logout}
                    className="text-sm cursor-pointer font-medium text-gray-500 group-hover:text-gray-700"
                  >
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>

          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </div>
    </Transition>
  );
};

type Props = {
  children: JSX.Element;
  title: string;
};

export function Dashboard({ children, title }: Props) {
  let history = useHistory();

  const {
    updateAuthToken,
    wrapWithLoading,
    fetchUser,
    updateUser,
    updateCampaign,
  } = useStoreActions((a) => a);
  const {
    user: { name },
  } = useStoreState((s) => s);

  const logout = () => {
    updateAuthToken("");
    updateUser(DEFAULT_USER_STATE);
    updateCampaign(DEFAULT_CAMPAIGN_STATE);
    history.push("/");
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!name) {
      wrapWithLoading({ action: fetchUser }).catch((e: Error) => {
        // if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
        //   setCampaignNotFound(true);
        // }
      });
    }
  }, [name, fetchUser, wrapWithLoading]);

  if (!name) {
    return <span>Loading</span>;
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
      <SidebarMobile
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        name={name}
        logout={logout}
      />
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <SidebarLogo />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {SIDEBAR_PRIMARY_LINKS.map((props) => (
                  <SidebarLink {...props} />
                ))}
                <OptionalCampaignLinks />
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <div className="flex items-center">
                <div>
                  <span
                    className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${
                      RANDOM_COLORS[
                        createHashBucket(name, RANDOM_COLORS.length)
                      ]
                    }`}
                  >
                    <span className="font-medium leading-none text-white">
                      {convertToTwoCharacters(name)}
                    </span>
                  </span>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    {name}
                  </p>
                  <p
                    onClick={logout}
                    className="text-xs cursor-pointer font-medium text-gray-500 group-hover:text-gray-700"
                  >
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: menu */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
              {/* Replace with your content */}
              {children}
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
