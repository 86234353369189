import React, { useEffect, useState } from "react";
import { useStoreActions } from "../../../store/hooks";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Dashboard } from "../../../components/application/dashboard";
import PhoneInput from "react-phone-number-input/input";

import "react-phone-number-input/style.css";

export function NewCampaign() {
  const history = useHistory();

  const [phoneValue, setPhoneValue] = useState();
  const { register, handleSubmit, errors, setError } = useForm();
  const { registerCampaign, fetchUser, wrapWithLoading } = useStoreActions(
    (a) => a
  );

  useEffect(() => {
    fetchUser().catch((e: Error) => {
      // if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
      //   setCampaignNotFound(true);
      // }
    });
  }, [fetchUser]);

  const onSubmit = (props: any) => {
    wrapWithLoading({
      action: registerCampaign,
      payload: props,
      message: "Creating Campaign",
    })
      .then((id: string) => {
        history.push(`/dashboard`);
      })
      .catch((e: Error) => {
        setError("submit", {
          type: "manual",
          message: e.message,
        });
      });
  };

  return (
    <Dashboard title="New Campaign">
      <>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-center mb-4 text-gray-500">
              Think of someone you care about:
            </h3>

            <div className="flex items-center justify-center flex-col">
              <form onSubmit={handleSubmit(onSubmit)} className="" action="#">
                <label className="block text-sm font-medium text-gray-700">
                  {" "}
                  Name
                </label>
                <div className="">
                  <input
                    id="recipientName"
                    type="text"
                    placeholder=""
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    ref={register({
                      required: true,
                    })}
                    name="recipientName"
                  />
                  {errors.recipientName && (
                    <span className="error-text">
                      Recipient name is required
                    </span>
                  )}
                </div>
                <br />
                <label
                  htmlFor="recipientPhoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="">
                  <PhoneInput
                    country="US"
                    id="recipientPhoneNumber"
                    type="text"
                    value={phoneValue}
                    onChange={setPhoneValue}
                    placeholder="555-555-5555"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                        message: "Invalid phone number",
                      },
                    })}
                    name="recipientPhoneNumber"
                  />
                  {errors.recipientPhoneNumber && (
                    <>
                      <span className="error-text">
                        {errors.recipientPhoneNumber.message}{" "}
                      </span>
                    </>
                  )}
                </div>

                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-blue">
                    Create Campaign
                  </button>

                  {errors.submit?.message && (
                    <>
                      <br />
                      <span className="error-text">
                        {errors.submit.message}
                      </span>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
}
