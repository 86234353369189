import React from "react";
import { useStoreState } from "../../store/hooks";
import { Link } from "react-router-dom";

export const NavbarT = () => {
  const { authToken } = useStoreState((s) => s);

  const loginLink = authToken ? "/dashboard" : "/new";

  return (
    <div className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center  py-6  md:space-x-10">
          <Link to="/">
            <div className="flex justify-start items-center lg:flex-1 logo">
              <svg
                width="16px"
                height="16px"
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
              <span>KindReminders</span>
            </div>
          </Link>
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-end md:flex-1">
              <Link
                to={loginLink}
                className={`${authToken ? "btn btn-white" : "btn btn-blue"}`}
              >
                {authToken ? "Dashboard" : "Sign in"}
              </Link>
            </div>
            {/* <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open menu</span>
                Heroicon name: menu
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="nav-space" />
    </div>
  );
};
