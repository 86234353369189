import React, { useEffect, useState } from "react";
import { Dashboard } from "../../../components/application/dashboard";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDragMove2Line } from "react-icons/ri";
import { Message } from "../../../store/store";
import { MAX_DATE } from "../../../store/constants";
import { Modal } from "../../../components/shared/modal";
import { ReactSortable } from "react-sortablejs";

export const ERRORS = {
  CAMPAIGN_FOUND_FOUND: "get campaign: datastore: no such entity",
};

const MessageEl = ({
  message,
  id,
  editMessageData,
  updateEditMessageData,
}: any) => {
  const handleUpdateMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    updateEditMessageData({ ...editMessageData, data: event.target.value });
  };

  if (id === editMessageData.id) {
    return (
      <textarea
        value={editMessageData.data}
        onChange={handleUpdateMessage}
        className="w-full mb-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
        maxLength={200}
        rows={5}
      />
    );
  } else {
    return message;
  }
};

const StatusIcon = ({ sentAt }: { sentAt: any }) => {
  if (sentAt) {
    const d = new Date(sentAt);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return (
      <>
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          Sent: {`${mo} ${da} ${ye}`}
        </span>
      </>
    );
  }
  return <></>;
};

const MessageTable = ({ messages, recipientName }: any) => {
  const { pushMessage, pushCampaign, wrapWithLoading } = useStoreActions(
    (a) => a
  );
  const { hasMessages } = useStoreState((a) => a);
  const [editMessageData, updateEditMessageData] = useState<{
    id?: number;
    data?: string;
  }>({});
  const [deleteMessageId, updateDeleteMessageId] = useState<number>();
  const { id: campaignId } = useParams<{ id: string }>();

  if (!hasMessages) {
    return (
      <>
        <div>There are 0 messages to send!</div>
        <br />
        <Link className="btn btn-blue" to={`${campaignId}/add`}>
          Add Messages
        </Link>
      </>
    );
  }

  const setEditMessage = (id: number, data: string) => {
    updateEditMessageData({ id, data });
  };

  const cancelEditMessage = () => {
    updateEditMessageData({});
  };

  const filteredMessages = messages
    .sort((a: Message, b: Message) => {
      return (
        new Date(a.sentAt).valueOf() - new Date(b.sentAt || MAX_DATE).valueOf()
      );
    })
    .filter((message: Message) => !message.deleted);

  const handleDragAndDrop = (messageList) => {
    const isDragging = messageList.some((message) => {
      return message.hasOwnProperty("chosen");
    });

    if (isDragging) {
      return;
    } else if (
      JSON.stringify(messageList) !== JSON.stringify(filteredMessages)
    ) {
      const deletedMessages = messages.filter((message) => message.deleted);
      wrapWithLoading({
        action: pushCampaign,
        payload: { messages: deletedMessages.concat(messageList) },
        message: "Updating Message List",
      });
    }
  };

  const submitDeleteMessage = () => {
    if (deleteMessageId) {
      wrapWithLoading({
        action: pushMessage,
        payload: {
          id: deleteMessageId,
          deleted: true,
        },
        message: "Deleting",
      }).then(() => {
        updateDeleteMessageId(undefined);
      });
    }
  };

  const submitEditMessage = () => {
    if (editMessageData.id && editMessageData.data) {
      wrapWithLoading({
        action: pushMessage,
        payload: {
          id: editMessageData.id,
          data: editMessageData.data,
        },
        message: "Saving",
      }).then(() => {
        updateEditMessageData({});
      });
    }
  };

  return (
    <div>
      <ReactSortable
        tag="ul"
        className="divide-y divide-gray-200 flex max-w-2xl flex-col justify-center m-auto"
        list={filteredMessages}
        handle=".drag-handle"
        setList={handleDragAndDrop}
        draggable=".unsent-msg"
      >
        {filteredMessages.map((message: Message) => {
          const isEditing = editMessageData.id === message.id;
          return (
            <li
              className={`relative bg-white py-5 px-4 ${
                message.sentAt ? "" : "unsent-msg"
              }`}
            >
              <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                  <div className="block focus:outline-none">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {message.senderName || "anonymous"}
                    </p>
                  </div>
                </div>
                {message.sentAt && (
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                    <StatusIcon sentAt={message.sentAt} />
                  </div>
                )}
                {!message.sentAt && (
                  <div className="flex">
                    {isEditing ? (
                      <>
                        <span
                          onClick={submitEditMessage}
                          className="btn-small btn-blue"
                        >
                          Save
                        </span>
                        <span
                          onClick={cancelEditMessage}
                          className="btn-small btn-white ml-2"
                        >
                          Cancel
                        </span>
                      </>
                    ) : (
                      <>
                        <RiDragMove2Line
                          size={16}
                          className="drag-handle mr-1 cursor-move"
                        />
                        <FiEdit
                          size={16}
                          className="cursor-pointer"
                          onClick={() =>
                            setEditMessage(message.id, message.data)
                          }
                        />
                        <HiOutlineTrash
                          className="cursor-pointer"
                          size={16}
                          onClick={() => updateDeleteMessageId(message.id)}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-1">
                <p className="line-clamp-2 text-sm text-gray-600">
                  <MessageEl
                    message={message.data}
                    id={message.id}
                    editMessageData={editMessageData}
                    updateEditMessageData={updateEditMessageData}
                  />
                </p>
              </div>
            </li>
          );
        })}
      </ReactSortable>

      <Modal
        primaryText={"Yes"}
        secondaryText={"No"}
        content={
          <span>
            Are you sure you want to delete this message? <br />
            <br />
            <strong>(This cannot be reverted!)</strong>
          </span>
        }
        headerText={<span>Delete message?</span>}
        primaryAction={() => {
          submitDeleteMessage();
        }}
        secondaryAction={() => {
          updateDeleteMessageId(undefined);
        }}
        isOpen={!!deleteMessageId}
      />
    </div>
  );
};

export const Messages = () => {
  const { wrapWithLoading, fetchCampaign, updateAuthToken } = useStoreActions(
    (a) => a
  );

  const [campaignNotFound, setCampaignNotFound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    campaign: { ownerEmail, messages, recipientName },
  } = useStoreState((s) => s);
  const { id: campaignId } = useParams<{ id: string }>();

  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const authToken = queryParams.get("authToken");
  if (authToken) {
    updateAuthToken(authToken);
    queryParams.delete("authToken");
    history.replace({
      search: queryParams.toString(),
    });
  }

  useEffect(() => {
    wrapWithLoading({ action: fetchCampaign, payload: campaignId })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch((e: Error) => {
        if (e.message === ERRORS.CAMPAIGN_FOUND_FOUND) {
          setCampaignNotFound(true);
        }
      });
  }, [fetchCampaign, campaignId, wrapWithLoading]);

  let content;

  if (campaignNotFound) {
    content = <div>Campaign {campaignId} not found</div>;
  } else if (!isLoaded) {
    content = <div>loading...</div>;
  } else if (!ownerEmail) {
    content = <div>You need to log in</div>;
  } else {
    content = (
      <MessageTable messages={messages} recipientName={recipientName} />
    );
  }

  return (
    <Dashboard title={`Compliments for ${recipientName}`}>
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">{content}</div>
      </div>
    </Dashboard>
  );
};
