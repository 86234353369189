import React from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "../store/hooks";

export const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: any;
  exact?: boolean;
  path: string;
}) => {
  const { authToken } = useStoreState((s) => s);
  const { updateAuthToken } = useStoreActions((a) => a);
  const history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search);
  const authTokenQueryParam = queryParams.get("authToken");
  if (authTokenQueryParam) {
    updateAuthToken(authTokenQueryParam);
    queryParams.delete("authToken");
    history.replace({
      search: queryParams.toString(),
    });
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Boolean(authToken || authTokenQueryParam) === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/new", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const PrivateRedirect = ({ ...rest }: any) => {
  const { updateAuthToken } = useStoreActions((a) => a);
  const history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search);
  const authTokenQueryParam = queryParams.get("authToken");
  if (authTokenQueryParam) {
    updateAuthToken(authTokenQueryParam);
    queryParams.delete("authToken");
    history.replace({
      search: queryParams.toString(),
    });
  }

  return (
    <Route>
      <Redirect {...rest} />
    </Route>
  );
};
