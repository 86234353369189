import React, { useState } from "react";
import { useStoreActions } from "../../store/hooks";
import { useForm } from "react-hook-form";
import { NavbarT } from "../../components/marketing/navbar";

import "react-phone-number-input/style.css";
import { Modal } from "../../components/shared/modal";

export function Signup() {
  const { register, handleSubmit, errors, setError } = useForm();
  const { registerUser, wrapWithLoading } = useStoreActions((a) => a);

  const [showModal, setShowModal] = useState(false);

  const onSubmit = (props: any) => {
    wrapWithLoading({
      action: registerUser,
      payload: props,
      message: "Signing up",
    })
      .then((id: string) => {
        setShowModal(true);
      })
      .catch((e: Error) => {
        setError("submit", {
          type: "manual",
          message: e.message,
        });
      });
  };

  return (
    <>
      <NavbarT />
      <form onSubmit={handleSubmit(onSubmit)} className="" action="#">
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center pb-64 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign up for Kind Reminders
            </h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Your name
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: true,
                      })}
                      id="name"
                      name="name"
                      type="name"
                      autoComplete="name"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  {errors.name?.message && (
                    <>
                      <span className="error-text">{errors.name.message} </span>
                    </>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  {errors.email?.message && (
                    <>
                      <span className="error-text">
                        {errors.email.message}{" "}
                      </span>
                    </>
                  )}
                </div>
                {/* <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div> */}

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={showModal}
        primaryAction={() => setShowModal(false)}
        primaryText={"Close"}
        content={"Please check email to log in!"}
        headerIcon={
          <svg
            className="h-6 w-6 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        }
        headerText="Success"
      />
    </>
  );
}
