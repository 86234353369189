import React from "react";
import { useStoreState } from "../../../store/hooks";
import "./styles.css";

export const Loading = () => {
  const {
    loading: { isLoading, message },
  } = useStoreState((s) => s);
  return (
    <>
      {isLoading && (
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="flex justify-center items-center h-full mt-8 text-lg font-extrabold">
            {message || "LOADING"}
          </div>
        </div>
      )}
    </>
  );
};
