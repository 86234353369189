import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Step1 } from "../../components/marketing/step1";
import { Step2 } from "../../components/marketing/step2";
import { Step3 } from "../../components/marketing/step3";
import { NavbarT } from "../../components/marketing/navbar";
import { Faq } from "../../components/marketing/faq";

const qaList = [
  {
    q: "What is a Kind Reminder?",
    a: "It's a daily affirmation sent to someone you care about",
  },
  {
    q: "Will this be meaningful to my person?",
    a:
      "Yes.  There are numerous scientific studies that suggest that this methodology is an effective way to lift someone's spirit or make them feel special",
  },
  {
    q: "Will this be hard?",
    a:
      "No.  We make it simple to create and schedule all your affirmations.  We also help you create the affirmations with our 'Compliement Helper'",
  },

  {
    q: "How do get other people to join?",
    a:
      "It's easy, whenever you create a campaign for someone.  We create a public link where anyone can contribute affirmations",
  },
  {
    q: "How much does it cost?",
    a:
      "It's $4 for as many Kind Reminders for one individual.  The same price as a greeting card.",
  },
  {
    q: "Couldn't I do this without your service?",
    a:
      "Sure you can.  It's just rather difficult to schedule the affimrations, organize the collection, and make all the affirmations look good.  We make it easy.",
  },
  {
    q: "Do I have to pay right away?",
    a:
      "Nope!  You can create a campaign to see how many messages you can collect for someone, and then pay after.",
  },
  {
    q: "Do I have control over the timing?",
    a:
      "You can control the starting date of the campaign.  Afterwards, affirmations are scheduled to send once per day to the recipient",
  },
  {
    q: "My friend lives in India, will this work?",
    a:
      "Unfortunately not, we only support United States and Canadian numbers at this time",
  },
  {
    q: "What am I actually sending? what will they receive?",
    a: (
      <span>
        We automate the deliver of daily affirmations to your recipients through
        text messages. They will recieve a daily image that looks like this:
        <div class="p-8 flex justify-center items-center">
          <img
            class="shadow-2xl lg:w-6/12 sm:10/12"
            src="https://us-central1-kind-reminders.cloudfunctions.net/kind-reminders-dev-images?sender=Your%20Name&message=Recipient,%20I%20love%20the%20way%20you%20appreciate%20the%20little%20things."
            alt=""
          />
        </div>
      </span>
    ),
  },
];

export function Home() {
  const history = useHistory();
  const handleStart = useCallback(() => history.push("/new/"), [history]);

  return (
    <>
      <NavbarT />
      <section className="text-gray-700 body-font">
        <div className="container px-8 mx-auto mb-12 lg:px-4 lg:pt-24 lg:mb-24">
          <div className="flex flex-col w-full lg:mb-4 text-left lg:text-center">
            <h1 className="mb-6 text-2xl tracking-tighter sm:text-5xl title-font text-center">
              Make someone feel very special
            </h1>
            <p className="mx-auto text-base font-medium leading-relaxed text-gray-700 lg:w-1/2 text-center">
              A unique social experience{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href="https://www.tandfonline.com/doi/figure/10.1080/15298868.2020.1761438"
              >
                (backed by science)
              </a>{" "}
              to remind someone how loved and appreciated they are
            </p>
          </div>
          <div className="mt-8 flex justify-center">
            <button className="btn-large btn-blue" onClick={handleStart}>
              {" "}
              Start Now{" "}
            </button>
          </div>
        </div>
      </section>
      <Step1 />
      <Step2 />
      <Step3 />
      <Faq qaList={qaList} />
    </>
  );
}
